import React from "react";
import PropTypes from "prop-types";
import { useBreakpoints } from "@hooks";
import Checkmark from "@svg/checkmark.svg";
import { Text, Image, imageProps } from "@components";

const HomeGrid = ({ logo, paperPencil, rows }) => {
  const { sm } = useBreakpoints();
  const logoWidth = sm ? 80 : 60;

  return (
    <div className="HomeGrid">
      <div className="max-w-lg mx-auto relative">
        <div className="HomeGrid__row sticky md:static top-0 bg-gray-3 z-10">
          <div className="HomeGrid__cell" />

          <div className="HomeGrid__cell">
            <div
              style={{ width: logoWidth, height: logoWidth }}
              className="inline-block rounded-full image-overflow-mask"
            >
              <Image image={logo} objectFit="contain" />
            </div>
          </div>

          <div className="HomeGrid__cell">
            <div
              style={{ width: logoWidth, height: logoWidth }}
              className="inline-block rounded-full image-overflow-mask"
            >
              <Image image={paperPencil} objectFit="contain" />
            </div>
          </div>
        </div>

        {rows.map(({ id, feature, isLorFeature, isPaperPencilFeature }) => (
          <div key={id} className="HomeGrid__row">
            <div className="HomeGrid__cell">
              <Text preset="p">{feature}</Text>
            </div>

            <div className="HomeGrid__cell">
              {isLorFeature && (
                <span className="block w-12 h-12">
                  <Checkmark />
                </span>
              )}
            </div>

            <div className="HomeGrid__cell">
              {isPaperPencilFeature && (
                <span className="block w-12 h-12">
                  <Checkmark />
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

HomeGrid.propTypes = {
  logo: imageProps.props.isRequired,
  paperPencil: imageProps.props.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
      isLorFeature: PropTypes.bool,
      isPaperPencilFeature: PropTypes.bool,
    })
  ).isRequired,
};

export default HomeGrid;
