import React, { useState } from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, AnimateIn } from "@components";

/* eslint react/prop-types: 0 jsx-a11y/media-has-caption: 0 */

const FeatureSlat = ({
  heading,
  subheading,
  description,
  image,
  cta,
  CtaIcon,
  Modal,
  className,
}) => {
  const [modalShowing, setModalShowing] = useState(false);

  return (
    <div className={className}>
      <AnimateIn preset="scaleIn">
        <div className="max-w-md mx-auto text-center flex flex-col md:flex-row items-center justify-center px-6">
          <div className="md:pr-8 text-center md:text-right">
            <Text preset="h3" className="text-orange uppercase mb-1">
              {subheading}
            </Text>
            <Text preset="h2" className="text-white mb-4" wrap="no-wrap">
              {heading}
            </Text>

            <RichText
              html={description.html}
              className="leading-loose max-w-xs md:ml-auto text-gray-light"
            />

            <div className="hidden md:flex justify-center md:justify-end mt-4">
              <div
                className="flex items-center text-white hover:text-orange transition cursor-pointer"
                onClick={() => setModalShowing(true)}
                role="presentation"
              >
                <div className="w-5 h-5 mr-1">
                  <CtaIcon />
                </div>
                <Text preset="label" className="cursor-pointer">
                  {cta}
                </Text>
              </div>
            </div>
          </div>

          <div
            className="max-w-xs cursor-pointer flex flex-col items-center w-36 md:w-48"
            onClick={() => setModalShowing(true)}
            role="presentation"
          >
            <div className="relative w-full mt-4 md:mt-0 cursor-pointer rounded-full overflow-hidden w-36 h-36 md:w-48 md:h-48">
              <Image image={image} ixParams={{ sat: "20" }} />
            </div>
          </div>

          <div className="flex md:hidden justify-center md:justify-end mt-6">
            <div
              className="flex items-center text-white hover:text-orange transition cursor-pointer"
              onClick={() => setModalShowing(true)}
              role="presentation"
            >
              <div className="w-5 h-5 mr-1">
                <CtaIcon />
              </div>
              <Text preset="label" className="cursor-pointer">
                {cta}
              </Text>
            </div>
          </div>
        </div>
      </AnimateIn>

      <Modal showing={modalShowing} setShowing={setModalShowing} />
    </div>
  );
};

FeatureSlat.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  cta: PropTypes.string.isRequired,
  CtaIcon: PropTypes.func.isRequired,
  Modal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default FeatureSlat;
