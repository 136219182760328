import React from "react";
import PropTypes from "prop-types";
import { Button } from "@components";
import { scrollToEl } from "@utils";

const PricingButton = ({ className }) => {
  return (
    <Button
      size="sm"
      onClick={() => scrollToEl(document.getElementById("Pricing"))}
      text="Get Started"
      className={className}
      color="blue"
      preset="outline"
    />
  );
};

PricingButton.propTypes = {
  className: PropTypes.string,
};

export default PricingButton;
