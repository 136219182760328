import React from "react";
import {
  Layout,
  SEO,
  Hero,
  ContactForm,
  Text,
  RichText,
  Testimonial,
  Prefooter,
  Plans,
  Image,
  AnimateIn,
} from "@components";
import Play from "@svg/play.svg";
import Maximize from "@svg/maximize.svg";
import { useHomePageData } from "@queries";
import classNames from "classnames";
import HomeGrid from "./HomeGrid";
import FeatureSlat from "./FeatureSlat";
import CaseStudyVideoModal from "./CaseStudyVideoModal";
import LeagueIframeModal from "./LeagueIframeModal";
import PricingButton from "./PricingButton";

/* eslint react/prop-types: 0 jsx-a11y/media-has-caption: 0 */

const HomePage = () => {
  const {
    logos,
    hero,
    grid,
    features,
    testimonials,
    gallery,
    plans,
    contact,
    prefooter,
    meta,
  } = useHomePageData();

  const leagueSpotlight = features.find(
    ({ ctaType }) => ctaType === "League Spotlight"
  );
  const caseStudy = features.find(({ ctaType }) => ctaType === "Video");

  return (
    <Layout className="text-white">
      <SEO {...meta} />

      <section className="bg-gray-2 px-6">
        <Hero {...hero} logos={logos} />
      </section>

      <section className="bg-gradient-gray">
        <HomeGrid logo={logos[0]} {...grid} />
      </section>

      <section className="relative bg-gray-3 pt-12 lg:pt-24 overflow-hidden">
        <div className="max-w-lg mx-auto">
          <AnimateIn preset="scaleIn" className="px-6 lg:px-0">
            <Text preset="h2" className="text-white mb-2">
              Advanced stats
            </Text>
            <Text preset="p" variant="lg" className="text-gray-light">
              {`Tracked and compiled automatically\nfor every game, every team, every player.`}
            </Text>
            <PricingButton className="mt-4 lg:mt-6" />
          </AnimateIn>

          <div className="sm:hidden">
            <Image
              image={gallery[5].image}
              objectFit="contain"
              className="h-full"
            />
          </div>

          <div className="hidden sm:flex flex-row items-end w-full sm:w-125">
            {gallery.slice(0, 4).map((item, idx) => (
              <div key={item.id} className="w-full sm:w-1/4 bg-gray-3">
                <div
                  className={classNames({
                    "w-full shadow transform": true,
                    "-translate-x-1/4": idx === 1,
                    "-translate-x-1/2": idx === 2,
                    "-translate-x-3/4": idx === 3,
                  })}
                >
                  <div
                    className="relative"
                    style={{
                      paddingBottom: `${
                        (item.image.height / item.image.width) * 100
                      }%`,
                    }}
                  >
                    <div className="absolute w-full h-full">
                      <Image
                        image={item.image}
                        objectFit="contain"
                        className="h-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-gradient-gray-reverse py-12 md:py-24 border-b-1 border-t-1 border-gray-4">
        <FeatureSlat
          {...leagueSpotlight}
          cta="Launch Site"
          CtaIcon={Maximize}
          Modal={LeagueIframeModal}
        />
      </section>

      <section className="relative bg-gray-3 pt-12 lg:pt-24 overflow-hidden border-b-1 border-gray-4">
        <div className="max-w-lg mx-auto">
          <AnimateIn preset="scaleIn" className="px-6 lg:px-0">
            <Text preset="h2" className="text-white mb-2">
              Simple Setup
            </Text>
            <Text
              preset="p"
              variant="lg"
              className="text-gray-light lg:whitespace-pre-wrap"
              wrap="none"
            >
              {`Get started in minutes with simple,\nstraightforward settings.`}
            </Text>
            <PricingButton className="mt-4 lg:mt-6" />
          </AnimateIn>

          <div className="sm:hidden">
            <Image
              image={gallery[10].image}
              objectFit="contain"
              className="h-full"
            />
          </div>

          <div className="hidden sm:flex flex-row items-end w-full sm:w-125">
            {gallery.slice(6, 10).map((item, idx) => (
              <div key={item.id} className="w-full sm:w-1/4 bg-gray-3">
                <div
                  className={classNames({
                    "w-full shadow transform": true,
                    "-translate-x-1/4": idx === 1,
                    "-translate-x-1/2": idx === 2,
                    "-translate-x-3/4": idx === 3,
                  })}
                >
                  <div
                    className="relative"
                    style={{
                      paddingBottom: `${
                        (item.image.height / item.image.width) * 100
                      }%`,
                    }}
                  >
                    <div className="absolute w-full h-full">
                      <Image
                        image={item.image}
                        objectFit="contain"
                        className="h-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-gradient-gray py-12 md:py-24 px-6">
        <AnimateIn
          preset="scaleIn"
          className="flex flex-col md:flex-row md:justify-space-around md:items-center max-w-lg mx-auto"
        >
          <div className="w-full md:w-1/2 md:pr-6 text-center md:text-left md:-mt-6">
            <Text preset="h2" className="text-white mb-2">
              Scoreboard Mode
            </Text>
            <Text
              preset="p"
              variant="lg"
              className="text-gray-light lg:whitespace-pre-wrap"
              wrap="none"
            >
              {`Turn any old monitor or TV into a\nhi-tech scoreboard.`}
            </Text>
            <PricingButton className="mt-4 lg:mt-6" />
          </div>

          <div className="overflow-hidden w-full md:w-1/2 mt-8 md:mt-0">
            <Image
              image={gallery[4].image}
              objectFit="contain"
              className="h-full"
            />
          </div>
        </AnimateIn>
      </section>

      <section className="bg-gradient-gray-reverse py-12 md:py-24 border-b-1 border-t-1 border-gray-4">
        <FeatureSlat
          {...caseStudy}
          cta="Play Video"
          CtaIcon={Play}
          Modal={CaseStudyVideoModal}
        />
      </section>

      <section className="bg-gradient-gray pt-12 md:pt-20 px-6">
        <AnimateIn
          preset="scaleIn"
          className="flex flex-col justify-center text-center max-w-sm mx-auto border-b-1 border-gray-4 pb-12 md:pb-20"
        >
          <Text preset="h2" className="text-white mb-2">
            Premium Support
          </Text>
          <Text
            preset="p"
            variant="lg"
            className="text-orange lg:whitespace-pre-wrap"
            wrap="none"
          >
            We&apos;re building lasting partnerships
          </Text>
          <Text
            preset="p"
            className="text-gray-light mt-4 lg:whitespace-pre-wrap"
            wrap="none"
          >
            We take pride in crafting unique solutions that save you time and
            provide immense value to your teams. No request is too big or too
            small.
          </Text>
          <div className="mt-4 lg:mt-6">
            <PricingButton />
          </div>
        </AnimateIn>
      </section>

      <section className="bg-gray-2 px-6">
        <div className="max-w-md mx-auto text-center mb-8 pt-12 lg:pt-24">
          <Testimonial {...testimonials[0]} size="lg" />
        </div>

        <div className="max-w-md mx-auto text-center md:flex pb-8 md:pb-12 lg:pb-20 border-b-1 border-gray-4">
          <div className="md:w-3/5 py-2 md:pr-12">
            <Testimonial {...testimonials[1]} />
          </div>

          <div className="md:w-2/5 py-2 md:pl-12">
            <Testimonial {...testimonials[2]} />
          </div>
        </div>
      </section>

      <section id="Pricing" className="bg-gradient-gray-reverse py-12 md:py-24">
        <div className="max-w-lg text-center px-6 mx-auto">
          <Text preset="h2" className="text-white">
            {plans.heading}
          </Text>
          <Text preset="p" className="text-gray-light mt-2 mb-3">
            {plans.subheading}
          </Text>
        </div>
        <div className="max-w-lg px-6 mx-auto mt-8">
          <Plans />
        </div>
        <div className="max-w-sm px-4 mx-auto mt-8 text-center">
          <AnimateIn preset="fadeIn">
            <RichText
              html={plans.disclaimer.html}
              className="text-gray-light italic"
            />
          </AnimateIn>
        </div>
      </section>

      <section className="bg-gray-3 pt-12">
        <div className="max-w-md mx-6 md:mx-auto bg-gray-4 p-8 shadow rounded">
          <Text preset="h2" className="text-white">
            {contact.heading}
          </Text>

          <Text preset="p" className="text-gray-light mt-2 mb-3 max-w-xs">
            {contact.subheading}
          </Text>

          <ContactForm />
        </div>
      </section>

      <section className="overflow-hidden bg-gray-3 pt-4 md:pt-12 lg:pt-16">
        <Prefooter {...prefooter} />
      </section>
    </Layout>
  );
};

export default HomePage;
