import React from "react";
import PropTypes from "prop-types";
import { Modal, Iframe, Text } from "@components";
import { useHomePageData } from "@queries";

/* eslint react/prop-types: 0 jsx-a11y/media-has-caption: 0 */

const Loading = () => (
  <div className="absolute inset-0 flex flex-col sm:p-12">
    <div className="flex-1 flex items-center justify-center bg-gray-2 border-2 border-blue">
      <Text preset="p">Fetching Leauge...</Text>
    </div>
  </div>
);

const ModalClose = ({ onClick }) => (
  <button
    type="button"
    className="absolute outline-none focus:outline-none bg-blue"
    style={{
      width: "2rem",
      height: "2rem",
      top: 0,
      right: 0,
      zIndex: 3,
    }}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="white"
      width="100%"
      height="100%"
    >
      <path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z" />
    </svg>
  </button>
);

const LeagueIframeModal = ({ showing, setShowing }) => {
  const { features } = useHomePageData();
  const leagueSpotlight = features.find(
    ({ ctaType }) => ctaType === "League Spotlight"
  );

  return (
    <Modal
      showing={showing}
      setShowing={setShowing}
      fullScreen
      ModalClose={ModalClose}
    >
      <div className="sm:p-12 w-full h-full bg-gray-1/2">
        {showing && (
          <Iframe
            src={leagueSpotlight.ctaLink.url}
            className="w-full h-full border-2 border-blue"
            Loading={Loading}
          />
        )}
      </div>
    </Modal>
  );
};

LeagueIframeModal.propTypes = {
  showing: PropTypes.bool.isRequired,
  setShowing: PropTypes.func.isRequired,
};

export default LeagueIframeModal;
