import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "@components";
import { useHomePageData } from "@queries";

/* eslint react/prop-types: 0 jsx-a11y/media-has-caption: 0 */

const CaseStudyVideoModal = ({ showing, setShowing }) => {
  const { features } = useHomePageData();
  const caseStudy = features.find(({ ctaType }) => ctaType === "Video");

  const video = useRef({});

  useEffect(() => {
    if (!showing) {
      video.current.pause();
    }
  }, [showing]);

  return (
    <Modal showing={showing} setShowing={setShowing}>
      <div className="relative" style={{ width: 800, maxWidth: "100%" }}>
        <div
          className="w-full relative bg-gray-3 color-white"
          style={{ paddingBottom: "57%" }}
        >
          <video
            ref={video}
            src={caseStudy.ctaLink.url}
            controls
            className="absolute inset-0 cursor-pointer z-20"
            poster={caseStudy.poster.url}
          />
        </div>
      </div>
    </Modal>
  );
};

CaseStudyVideoModal.propTypes = {
  showing: PropTypes.bool.isRequired,
  setShowing: PropTypes.func.isRequired,
};

export default CaseStudyVideoModal;
